/* You can add global styles to this file, and also import other style files */
@import '~@aws-amplify/ui-angular/theme.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.logoImage {
  object-fit: cover;
  width: 100px;
  height: auto;
}

.dropdown-logoImage {
  object-fit: cover;
  width: 40px;
  height: auto;
}

.nowrap {
  white-space: nowrap;
}

.col-min-width {
  min-width: 7em;
}

.pre-wrap-cr {
  margin-bottom: 2rem;
  white-space: pre-wrap;
}

.rate-button {
  background: #beddf2;
  /* Old browsers */
  background: -moz-linear-gradient(top, #beddf2 0%, #cce9f6 50%, #c6e6f6 51%, #e0f2fa 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #beddf2 0%, #cce9f6 50%, #c6e6f6 51%, #e0f2fa 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #beddf2 0%, #cce9f6 50%, #c6e6f6 51%, #e0f2fa 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beddf2', endColorstr='#e0f2fa', GradientType=0);
  /* IE6-9 */
  border: none;
  color: black;
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
}

.menu-button {
  background: #beddf2;
  /* Old browsers */
  background: -moz-linear-gradient(top, #beddf2 0%, #cce9f6 50%, #c6e6f6 51%, #e0f2fa 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #beddf2 0%, #cce9f6 50%, #c6e6f6 51%, #e0f2fa 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #beddf2 0%, #cce9f6 50%, #c6e6f6 51%, #e0f2fa 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#beddf2', endColorstr='#e0f2fa', GradientType=0);
  /* IE6-9 */
  border: none;
  color: black;
  padding: 1px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 20px;
}

.red-color {
  background: #c03;
}

.input-width {
  width: 30%
}

.validation-summary {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
}

.validation-summary p {
  margin-left: 10px;
}

:root {
  --amplify-primary-color: #c03;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}


mat-dialog-container {
  background-color: #FAFAFA !important;
}